.intro-container,
.headshot-container {
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headshot-container {
    height: 180px;
    width: 180px;
    margin: 20px auto;
}

.divider {
    margin-top: 30px;
    background-color: #936319;
    height: 5px;
    width: 100%;
}