.contact-header {
    text-align: center;
    margin: 35px auto;
    padding: 10px;
    background-color: #936319;
    color: #f7e8cb;
    width: 280px;
}

ul {
    list-style-type: none;
    padding-left: 0px;
}

.contact-link {
    margin: 10px auto;
    padding: 10px;
    border: 1px #936319 solid;
    width: 250px;
    text-align: center;
}

.contact-link a {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.contact-link a:hover,
.contact-link a:focus,
.contact-link a:visited {
    color: black;
}


@media (min-width: 1000px) {
    .contact-link-list {
        display: flex;
        flex-direction: row;
    }
}