.project-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.screengrabs-container {
    width: 220px;
    margin: 20px auto;
}
