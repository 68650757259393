.hero-image {
  background-color: orange;
  height: 500px;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../Images/cactuslaptop.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.name-container {
  background-color: #f7e8cb;
  opacity: 0.9;
  padding: 40px 60px;
}

.name-container h1 {
  font-family: 'Homemade Apple', cursive;
}

.name-container h2 {
  font-family: 'Montserrat', sans-serif;
}

.hero-text {
  font-size: 16px;
  text-align: center;
  color: #6f5f50;
}

.hero-btn {
  cursor: pointer;
  display:inline-block;
  padding: 5px 20px;
  border: 3px solid #FFFFFF;
  margin:0 0 100px 0;
  box-sizing: border-box;
  text-transform:uppercase;
  font-family:'Roboto',sans-serif;
  color:#FFFFFF;
  text-align:center;
  background-color: transparent;
}

.hero-image {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


@media (max-width: 650px) {
  .hero-image {
    justify-content: center;
  }
  .name-container {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .name-container h1 {
    font-size: 22px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .hero-text {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .hero-image {
    height: 600px;
  }
}

@media (min-width: 1101px) {
  .hero-image {
    height: 650px;
  }
}