h4 {
    font-size: 25px;
    text-align: center;
    font-family: 'Homemade Apple', cursive;
}

.live-repo {
    margin-top: 50px;
    text-align: center;
}

.live-repo a {
    background-color: tan;
    border-radius: 15px;
    padding: 10px;
    margin: 0px 10px;
    cursor: pointer;
    text-decoration: none;
    font-weight: 700;
}

.live-repo a:focus,
.live-repo a:visited,
.live-repo a:hover {
    color: black;
}

/* Larger font for larger screens */

@media (min-width: 1024px) {
    h4 {
        font-size: 30px;
    }
    .description p {
        font-size: 20px;
    }
}