.app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.main-container {
    margin: 40px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 768px) {
    .main-container {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1024px) {
    .main-container {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
}